var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.settingsComponent, {
    tag: "component",
    attrs: {
      "widgetSettings": _vm.widgetSettings,
      "dimensionsSelected": _vm.dimensionsSelected
    },
    on: {
      "changeWidgetSettings": function changeWidgetSettings($event) {
        return _vm.$emit('changeWidgetSettings', $event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }