<template>
  <component
    :is="settingsComponent"
    :widgetSettings="widgetSettings"
    :dimensionsSelected="dimensionsSelected"
    @changeWidgetSettings="$emit('changeWidgetSettings', $event)"
  />
</template>

<script>
import get from 'lodash/get'
import { TalpaLoaderWrapper } from '@common/components'
import WidgetError from '@/components/Widgets/Widget/WidgetError'

export default {
  props: {
    widgetSettings: {
      type: Object,
    },
    widget: {
      type: Object,
      required: true,
    },
    dimensionsSelected: {
      type: Array,
    },
  },
  data() {
    return {
      settingsComponent: null,
    }
  },
  computed: {
    settingsComponentName() {
      const type = get(this.widget, 'name', 'unknown')
        .split('_')
        .map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
        .join('')
      return type
    },
  },
  watch: {
    settingsComponentName: {
      handler(type) {
        this.settingsComponent = () => {
          if (!type) {
            return {
              component: WidgetError,
            }
          }
          return {
            component: import('./' + type + 'Settings.vue'),
            loading: TalpaLoaderWrapper,
            error: WidgetError,
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleKeyUp(e) {
      this.$emit('update:title', e.target.value)
    },
  },
}
</script>
